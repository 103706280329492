<template>
  <q-form ref="editForm">
    <c-card title="화기작업 상세정보" class="cardClassDetailForm no-margin">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            label="작업개요"
            name="workSummary"
            v-model="workPermit.workSummary">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-process
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            :plantCd="workPermit.plantCd"
            :changeItem="changeItem"
            label="공종"
            name="processCd"
            v-model="workPermit.processCd"
            >
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            label="작업명"
            name="sopName"
            v-model="workPermit.sopName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-vendor
            :required="true"
            :disabled="!isWriting || isVendor"
            :editable="editable"
            :plantCd="workPermit.plantCd"
            label="작업업체"
            name="vendorCd"
            v-model="workPermit.vendorCd"
            @dataChange="vendorChange" />
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-project-location
            :disabled="!isWriting"
            :editable="editable"
            :plantCd="workPermit.plantCd"
            :changeItem="changeItem"
            label="작업장소"
            name="sopMapId"
            v-model="workPermit.sopMapId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <c-textarea
            :disabled="!isWriting"
            :editable="editable"
            label="특별요구사항"
            name="specialRequirements"
            v-model="workPermit.specialRequirements">
          </c-textarea>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="!isWriting"
            :editable="editable"
            label="관련 기계/장비"
            name="saiContructFacilityId"
            v-model="workPermit.saiContructFacilityId">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            :label="requestLabel"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <c-checkbox
            :disabled="!isWriting"
            :editable="editable"
            codeGroupCd="SOP_PROTECTIVE_GEAR_CD"
            :isObject="true"
            valueText="sopProtectiveGearName"
            valueKey="sopProtectiveGearCd"
            label="보호구"
            name="protectiveGears"
            v-model="workPermit.protectiveGears"
          />
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
        <c-table
          class="q-mt-md"
          title="안전조치 요구사항"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="workPermit.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
        <c-table
          ref="gasTable"
          class="q-mt-md"
          title="가스농도 측정"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="workPermit.gases"
          :plantCd="workPermit.plantCd"
          :vendorCd="workPermit.vendorCd"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          selection="multiple"
          rowKey="sopGasCheckId"
        >
          <!-- <template slot="customTableTitleEditor">
            <q-card class="custom-card no-margin" flat bordered>
              <q-card-section horizontal class="cardhead bg-grey-3">
                <q-card-section class="q-pa-xs">
                  <div class="gas-card-title text-grey-8" style="display:inline;">가스점검</div>
                  <div class="text-caption text-grey q-pl-xs" style="display:inline;">
                    가스농도 측정에 필요한 기기와 점검/확인자를 지정하세요.
                  </div>
                </q-card-section>
              </q-card-section>

              <q-separator />

              <q-card-actions>
                <div class="col-4 gas-item">
                  <c-text
                    :editable="editable"
                    label="점검기기명"
                    name="col1"
                    v-model="workPermit.col1">
                  </c-text>
                </div>
                <div class="col-4 gas-item">
                  <c-text
                    :editable="editable"
                    label="점검자"
                    name="col1"
                    v-model="workPermit.col1">
                  </c-text>
                </div>
                <div class="col-4 gas-item">
                  <c-text
                    :editable="editable"
                    label="확인자(입회자)"
                    name="col1"
                    v-model="workPermit.col1">
                  </c-text>
                </div>
              </q-card-actions>
            </q-card>
          </template> -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&isWriting" label="추가" icon="add" :showLoading="false" @btnClicked="addGas" />
              <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="removeGas" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-card title="발급 정보" class="cardClassDetailForm no-margin">
          <template slot="card-detail">
            <div class="col-6">
              <c-field
                :required="true"
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="issuedDeptCd"
                type="dept_user"
                label="발급"
                beforeText=""
                name="issuedUserId"
                v-model="workPermit.issuedUserId" />
            </div>
            <div class="col-6">
              <c-field
                :required="true"
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="approvalDeptCd"
                type="dept_user"
                label="승인"
                beforeText=""
                name="approvalUserId"
                v-model="workPermit.approvalUserId" />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-card title="협조자 정보" class="cardClassDetailForm no-margin">
          <template slot="card-detail">
            <div class="col-6">
              <c-field
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="relationCooperation1DeptCd"
                type="dept_user"
                label="협조자-1"
                beforeText=""
                name="relationCooperation1UserId"
                v-model="workPermit.relationCooperation1UserId" />
            </div>
            <div class="col-6">
              <c-field
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="relationCooperation2DeptCd"
                type="dept_user"
                label="협조자-2"
                beforeText=""
                name="relationCooperation2UserId"
                v-model="workPermit.relationCooperation2UserId" />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-card title="안전조치확인 정보" class="cardClassDetailForm no-margin">
          <template slot="card-detail">
            <div class="col-6">
              <c-field
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="maintenanceDeptCheckDeptCd"
                type="dept_user"
                label="책임자"
                name="maintenanceDeptCheckUserId"
                v-model="workPermit.maintenanceDeptCheckUserId" />
            </div>
            <div class="col-6">
              <c-field
                :disabled="!isWriting"
                :editable="editable"
                :data="workPermit"
                :plantCd="workPermit.plantCd"
                :vendorCd="workPermit.vendorCd"
                :changeItem="changeItem"
                :isVendorOrg="true"
                deptValue="maintenanceDeptEntryDeptCd"
                type="dept_user"
                label="입회자"
                name="maintenanceDeptEntryUserId"
                v-model="workPermit.maintenanceDeptEntryUserId" />
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import eventBus from '@/js/eventBus';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-fire',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 공사현장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          pjmProjectAreaId: '',
          processCd: '',  // 작업공종
          workLocation: '',  // 작업장소
          saiContructFacilityId: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          deleteCheckResults: [],
          gases: [],
          gasChecks: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
    changeItem: {
      type: Object,
      default: function() {
        return {
          plant: '',
          process: '',
          vendor: '',
        }
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '310px'
      },
      grid2: {
        columns: [],
        height: '310px'
      },
      useGasItems: [],
      sopEditing: false,
      editable: true,
      listCheckItemUrl: '',
      listGasItemUrl: '',
      listSopUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    overtime() {
      return this.workPermit.col8.length > 0;
    },
    request() {
      let text = this.workPermit.applicationDeptName !== this.workPermit.applicationUserName ? 
        this.workPermit.applicationDeptName + ' / ' + this.workPermit.applicationUserName : this.workPermit.applicationUserName
      return text;
    },
    requestLabel() {
      let text = this.workPermit.applicationDeptName !== this.workPermit.applicationUserName ? 
        '신청인' : '신청업체'
      return text;
    },
    isSopEdit() {
      return Boolean(!this.workPermit.mdmSopId && this.workPermit.sopName) || this.sopEditing
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    'changeItem.plant': {
      handler: function () {
        if (!this.workPermit.deleteCheckResults) {
          this.workPermit.deleteCheckResults = [];
        }
        this.$_.forEach(this.workPermit.checkResults, item => {
          if (this.$_.findIndex(this.workPermit.deleteCheckResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
            this.workPermit.deleteCheckResults.push(item)
          }
        })
        this.$set(this.workPermit, 'checkResults', [])
        this.$set(this.workPermit, 'gases', [])
        this.getCheckItemList();
        this.getGasItemList();
      },
      deep: false,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    eventBus.$on('vaildForm', (type)=>{
      if (this.$refs['editForm']) {
        this.$refs['editForm'].validate().then(_result => {
          this.$emit('validResult', _result, type);
        });
      }
    });
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') {
        this.workPermit.vendorCd = this.$store.getters.user.deptCd;
      }
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.listGasItemUrl = selectConfig.sop.swp.gas.list.url
      this.listSopUrl = selectConfig.mdm.sop.jobhazard.url;
      // code setting
      // list setting
      this.getCheckItemList();
      this.getGasItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '항목',
          align: 'left',
          sortable: true,
        },
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: '필요',
          align: 'center',
          sortable: true,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
        {
          name: 'confirmFlag',
          field: 'confirmFlag',
          label: '확인',
          align: 'center',
          sortable: true,
          type: 'check',
          disableTarget: 'necessaryFlag',
          disableCon: 'Y',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
      ]
      if (!this.workPermit.checkResults) this.workPermit.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000005',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.workPermit.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: '',  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'N',  // 필요
                confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.workPermit.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: '',  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: '확인자',
              align: 'center',
              sortable: true,
              type: 'user',
              userId: 'confirmUserId',
              disableTarget: 'necessaryFlag',
              disableCon: 'Y',
            })
          }
        },);
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            sortable: true,
            type: 'user',
            userId: 'confirmUserId',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
          })
        }
      }
    },
    getGasItemList() {
      let columns = [
        {
          name: 'checkTime',
          field: 'checkTime',
          label: '점검시간',
          align: 'center',
          type: 'datetime',
          minuteStep: 10,
          sortable: false,
          style: 'width:15%',
        },
        {
          name: 'gasCheckMachine',
          field: 'gasCheckMachine',
          label: '점검기기',
          align: 'center',
          sortable: false,
          type: 'text',
          value: '',
          setHeader: true,
          style: 'width:15%',
        },
        {
          name: 'gasCheckUserName',
          field: 'gasCheckUserName',
          label: '점검자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasCheckUserId',
          value: '',
          setHeader: true,
          style: 'width:10%',
        },
        {
          name: 'gasConfirmUserName',
          field: 'gasConfirmUserName',
          label: '확인자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasConfirmUserId',
          value: '',
          setHeader: true,
          style: 'width:10%',
        },
      ];

      this.$http.url = this.listGasItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.workPermit.plantCd,
        // sopWorkTypeCd: 'SWT0000005',
      }
      this.$http.request((_result) => {
        this.useGasItems = [];
        if (this.workPermit.gases && this.workPermit.gases.length > 0) {
          let pushData = [];
          this.$_.forEach(this.workPermit.gases, gas => {
            for (let gasValue in gas) {
              let idx = this.$_.findIndex(_result.data, { sopGasId: gasValue });
              let idx2 = this.$_.findIndex(pushData, { sopGasId: gasValue });
              /**
               * idx의 경우 기본적으로 존재하는지 여부를 판단하지만
               *            예전 입력된 값 중 useFlag N처리된 값이 있는 경우는 허용
               */
              if ((idx > -1 && gas[gasValue] !== '-') && idx2 === -1) {
                this.$set(_result.data[idx], 'me')
                pushData.push(_result.data[idx])
              }
            }
          })
          if (pushData && pushData.length > 0) {
            this.$_.forEach(pushData, item => {
              this.useGasItems.push(item)
              columns.splice(1, 0, {
                // 가스 헤더 적용 로직 추가 필요
                name: item.sopGasId + '_p',
                field: item.sopGasId + '_p',
                label: item.gasName,
                align: 'center',
                sortable: false,
                child: [
                  {
                    name: item.sopGasId,
                    field: item.sopGasId,
                    label: item.safetyStandards,
                    align: 'center',
                    type: 'text',
                    sortable: false,
                    style: 'width:' + (50 / pushData.length) + '%',
                  },
                ]
              })
            })
          }
        } else {
          let useYList = this.$_.filter(_result.data, item => {
            return item.useFlag === 'Y' && this.$_.findIndex(item.workTypes, { sopWorkTypeCd: 'SWT0000005' }) > -1
          });
          this.$_.forEach(useYList, gas => {
            this.useGasItems.push(gas)
            columns.splice(1, 0, {
              // 가스 헤더 적용 로직 추가 필요
              name: gas.sopGasId + '_p',
              field: gas.sopGasId + '_p',
              label: gas.gasName,
              align: 'center',
              sortable: false,
              child: [
                {
                  name: gas.sopGasId,
                  field: gas.sopGasId,
                  label: gas.safetyStandards,
                  align: 'center',
                  type: 'text',
                  sortable: false,
                  style: 'width:' + (50 / useYList.length) + '%',
                },
              ]
            })
          })
        }

        this.grid2.columns = columns
      },);
    },
    addGas() {
      let pushData = {
        sopGasCheckId: uid(),  // 가스농도측정번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId, // 작업허가서 일련번호
        supplementWorkTypeCd: '',  // 보충작업구분
        checkTime: '',  // 점검시간
        gasCheckMachine: '',  // 점검기기명
        gasCheckUserName: '',
        gasCheckUserId: '',  // 점검자
        gasConfirmUserName: '',
        gasConfirmUserId: '',  // 확인자(입회자)
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      }
      this.$_.forEach(this.useGasItems, gas => {
        this.$set(pushData, gas.sopGasId, '0');
      });
      
      this.workPermit.gases.push(pushData);
    },
    removeGas() {
      let selectData = this.$refs['gasTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.workPermit.deleteGasChecks, { sopGasCheckId: item.sopGasCheckId }) === -1
            && item.editFlag !== 'C') {
            this.workPermit.deleteGasChecks.push(item);
            this.$_.forEach(this.useGasItems, gas => {
              this.workPermit.deleteGasCheckVals.push({
                sopGasCheckId: item.sopGasCheckId,
                sopGasId: gas.sopGasId
              })
            })
          }
          this.workPermit.gases = this.$_.reject(this.workPermit.gases, item);
          this.$refs['gasTable'].$refs['compo-table'].clearSelection();
        });
      }
    },
    vendorChange(vendorCd, beforeVendorCd, flag) {
      if (this.workPermit.workers && this.workPermit.workers.length > 0 && this.editable && this.isWriting && !flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '해당 작업업체별로 지정된 작업자 정보들이 제외됩니다.\n\r진행하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.workPermit.deleteWorkers) {
              this.workPermit.deleteWorkers = [];
            }
            this.$_.forEach(this.$_.filter(this.workPermit.workers, { vendorCd: beforeVendorCd }), item => {
              if (this.$_.findIndex(this.workPermit.deleteWorkers, { sopWorkerId: item.sopWorkerId }) === -1) {
                this.workPermit.deleteWorkers.push(item)
              }
            })
            this.workPermit.workers = this.$_.reject(this.workPermit.workers, { vendorCd: beforeVendorCd })
          },
          // 취소 callback 함수
          cancelCallback: () => {
            this.$set(this.workPermit, 'vendorCd', beforeVendorCd)
          },
        });
      } else {
        if (!this.workPermit.deleteWorkers) {
          this.workPermit.deleteWorkers = [];
        }
        this.$_.forEach(this.$_.filter(this.workPermit.workers, { vendorCd: beforeVendorCd }), item => {
          if (this.$_.findIndex(this.workPermit.deleteWorkers, { sopWorkerId: item.sopWorkerId }) === -1) {
            this.workPermit.deleteWorkers.push(item)
          }
        })
        this.workPermit.workers = this.$_.reject(this.workPermit.workers, { vendorCd: beforeVendorCd })
      }
      this.$set(this.changeItem, 'vendor', uid())
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
  }
};
</script>
<style lang="sass">
.gas-item
  padding-top:18px !important
  .q-field__inner
    padding-bottom: 0px !important
  .customText
    padding-bottom: 0px !important
.gas-card-title
  font-size: 15px
  font-weight: 800
</style>